<template>
  <div class="context">
    <van-notice-bar left-icon="volume-o" v-if="NoticeData" scrollable mode="closeable"> {{ NoticeData.noticeTxt
      }}</van-notice-bar>
    <!-- 没有商品时 -->
    <div style="flex:1;overflow: scroll;">
      <div v-if="carData.length === 0" class="height-100">
        <van-empty description="未添加商品，去购物" class="empt font-s-16"> </van-empty>
      </div>
      <!-- 单独的商品 -->
      <van-checkbox-group v-model="checkedShopKeys" ref="checkboxGroup" @change="handleShopCheck">
        <div v-for="(item, index) in carData" :key="index" class="goods">
          <!-- <div class="goods-no-selected" v-if="gooodsType !== null && item.productFlag !== gooodsType"></div> -->
          <van-swipe-cell>
            <div class="flex p-b-10">
              <div class="p-lr-10">
                <van-checkbox checked-color="#ee0a24" :name="item.id"
                  :disabled="!edit && (!item.deliveryFlag || item.status === '2' || item.status === '3')" />
              </div>
              <div class="mr-10"></div>
              <div style="width: 100%;">
                <div class="flex" style="width: 100%;">
                  <div>
                    <van-image class="img" :src="item.coverImage" alt="" fit="cover" radius="0.5rem" />
                  </div>
                  <div class="p-t-10 p-l-10 width-100">
                    <div class="name">{{ item.productName }}</div>
                    <div class="skuname">{{ item.skuName }}</div>

                    <div class="flex jcsb" style="padding-right: 1rem;">
                      <div style="color: #d33b2e">{{ item.showPrice }}点</div>
                      <div>
                        <van-stepper disable-input v-model="item.productNum"
                          @change="(value) => handleProductNumChange(item, value)"
                          :max="item.limitNum ? item.limitNum : '999'" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <span class="tig" v-if="!item.deliveryFlag">不支持配送</span>
                  <span class="tig" v-if="item.status === '2'">该商品已下架</span>
                  <span class="tig" v-if="item.status === '3'">该商品补货中</span>
                </div>
              </div>
            </div>
            <template #right>
              <div class="del-alsid" @click="delgoods(item, index)">删除</div>
            </template>
          </van-swipe-cell>
        </div>
      </van-checkbox-group>
    </div>
    <!-- 底部 -->
    <div :style="{ width: '100%' }">
      <MyCartFooter @check="checkAll" :total="totalPrice" :carData="carData" :select-data="checkedShopKeys" ref="MyCartFooter"
        :type="storeType" :edit="edit" @onDelete="onDeleteAll"></MyCartFooter>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import MyCartFooter from "./MyCartFooter.vue";
import { typeMaps } from "./indext.action";
export default {
  name: "shopCards",
  props: {
    storeType: {
      type: String,
      required: true,
      default: 1
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  components: { MyCartFooter },
  data() {
    return {
      bottom: 0,
      carData: [],
      checkedShopKeys: [], // 选着商品
      isCheckedAll: false, // 购物车商品是否全选
      userId: "", // 用户id
      freecaId: "", // 福利卡id
      totalPrice: 0, // 合计金额
      NoticeData: null //通知
    };
  },
  watch: {
    checkedShopKeys(newVal) {
      this.updateTotalNum();
    },
    edit(value) {
      this.checkAll(false);
      this.$refs.MyCartFooter.change(false)
    }
  },
  computed: {
    // gooodsType: function () {
    //   const selectedArr = this.checkedShopKeys;
    //   if (selectedArr.length > 0) {
    //     // 当前一个选中的
    //     const selectCar = this.carData.find((item) => this.checkedShopKeys.some((skuId) => skuId === item.skuId));
    //     return selectCar.productFlag;
    //   } else if (selectedArr.length === 0) {
    //     // 没有被选中的
    //     return null;
    //   }
    // }
  },
  mounted() {
    const userInfo = localStorage.getItem("userInfo"); // 用户信息
    this.userId = userInfo ? JSON.parse(userInfo).id : "";
    this.freecaId = localStorage.getItem("cardId");
    this.bottom = localStorage.getItem("entrCode") == 'E4' ? 3.5 : 0
    this.getCard(); // 查询购物车list
    this.getNotice();
  },

  methods: {
    updateTotalNum() {
      const selectCar = this.carData.filter((item) => this.checkedShopKeys.some((id) => id === item.id));
      this.totalPrice = selectCar.reduce((total, current) => {
        const { productNum, showPrice } = current;
        const newTotal = Number(total) + Number(showPrice) * Number(productNum);
        return newTotal;
      }, 0);
      this.totalPrice = Math.round(this.totalPrice * 100) / 100
    },
    // 请求购物车数据
    async getCard() {
      const typeMap = typeMaps.find((item) => item.typeCode === this.storeType);
      const params = {
        userId: this.userId,
        sourceChannel: "购物车",
        type: typeMap ? typeMap.typeValue : typeMaps[0].typeValue,
        cardId: this.freecaId
      };

      const res = await this.$api.getCard(params);
      this.carData = res.data;
      this.checkedShopKeys = [];
    },
    //通知
    async getNotice() {
      const res = await this.$api.queryNotice();
      if (res && res.code === 0) {
        this.NoticeData = res.data
      }
    },

    checkAll(checked) {
      this.$refs.checkboxGroup.toggleAll({
        checked: checked,
        skipDisabled: true,
      });
    },
    changeAll(){
      this.$refs.MyCartFooter.change(false)
    },
    async handleProductNumChange(record, productNum) {
      Toast.loading({ forbidClick: true });
      const res = await this.$api.updateNum({
        ...record,
        productNum
      });
      if (res.code === 0) {
        this.carData = this.carData.map((item) => {
          if (item.id === record.id) {
            Toast.clear();
            return {
              ...item,
              productNum
            };
          }
          return item;
        });
        this.updateTotalNum();
      } else {
        Toast(res.msg);
        this.carData = this.carData.map((item) => {
          if (item.id === record.id) {
            productNum -= 1;
            return {
              ...item,
              productNum
            };
          }
          return item;
        });
        return;
      }
    },
    async handleProductNumChange2(record, productNum) {
      Toast.loading({ forbidClick: true });
      const res = await this.$api.updateNum({
        ...record,
        productNum
      });
      if (res.code === 0) {
        this.carData = this.carData.map((item) => {
          if (item.id === record.id) {
            Toast.clear();
            return {
              ...item,
              productNum
            };
          }
          return item;
        });
        this.updateTotalNum();
      } else {
        Toast(res.msg);
        this.carData = this.carData.map((item) => {
          if (item.id === record.id) {
            productNum -= 1;
            return {
              ...item,
              productNum
            };
          }
          return item;
        });
        return;
      }
    },
    //删除多个
    onDeleteAll() {
      let form = [];
      this.carData.forEach((element) => {
        if (this.checkedShopKeys.includes(element.id)) {
          form.push(element);
        }
      });
      this.$Dialog
        .confirm({
          title: "删除商品",
          message: "是否将该商品删除"
        })
        .then(() => {
          this.$api
            .deleteShop(form)
            .then((res) => {
              if (res.code == 1) {
                this.$Toast(res.msg);
                return
              }
              this.getCard();
            })
            .catch((err) => {
              // console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    //删除单个
    delgoods(data, index) {
      console.log(this.carData);
      this.$Dialog
        .confirm({
          title: "删除商品",
          message: "是否将该商品删除"
        })
        .then(() => {
          this.$api
            .deleteShop([data])
            .then((res) => {
              if (res.code == 1) {
                this.$Toast(res.msg);
                return
              }
              // console.log(res);
              // 页面显示的数据
              this.getCard();
            })
            .catch((err) => {
              // console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    handleShopCheck() { },
    shopUpdateChange() {
      // 商品信息变更触发事件
      this.$emit();
    }
  }
};
</script>

<style lang="scss" scoped>
.empt {
  transform: translateY(50%);
}

.context {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.empt1 {
  transform: translateY(30%);
}

.box {
  padding-bottom: 9rem;
  overflow: auto;

  .van-button--primary {
    margin-right: 15px;
  }

  .width-order {
    width: 50%;
    padding-right: 0.5rem;

    .width-sum {
      width: 45%;
      font-size: 0.8rem;
    }
  }
}

.title {
  background-color: rgb(236, 224, 216);
  height: 1.5rem;
}

.orderhead {
  background-color: white;
  width: 100%;
  position: absolute;
  bottom: 4rem;
  font-size: 0.8rem;
  height: 2.5rem;
}

.goods {
  background-color: #fff;
  margin: 10px;
  border-radius: 5px;
  position: relative;

  .goods-no-selected {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
    background: rgb(218 216 218 / 40%);
  }

  .img {
    width: 5.5rem;
    height: 5.5rem;
  }

  .name {
    min-height: 2.5rem;
    padding-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .skuname {
    font-size: 0.85rem;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .del-alsid {
    display: flex;
    align-items: center;
    width: 3rem;
    background-color: red;
    height: 100%;
    color: white;
    justify-content: center;
    font-size: 18px;
  }
}

.tig {
  color: #D33B2E;
  font-size: 0.85rem;
  margin-right: 10px;
}

.van-button {
  height: 1.5rem;
  width: 4rem;
  padding: 0;
}

body::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}
</style>
