<template>
  <!-- <van-submit-bar :price="total" button-text="结算" @submit="onSubmit">
    <van-checkbox checked-color="#ee0a24" :loading="submitLoading" v-model="checked"
      @change="handleCheckedChang">全选</van-checkbox> -->


  <div
    style="background-color: white ;height: 4rem;line-height: 4rem;  width: 100%; display: flex;flex-direction: row;justify-content: space-between;">
    <div style="display: flex;flex-direction: row;padding-left: 0.5rem;">
      <van-checkbox checked-color="#ee0a24" :loading="submitLoading" v-model="checked"
        @change="handleCheckedChang">全选</van-checkbox>
      <div>
        <span style="margin-left: 0.5rem;color: #666;"> 合计</span><span style="color:#D33B2E;font-weight: 600;"> {{
        total
      }}点</span>
      </div>

    </div>
    <div v-if="edit" @click="onDelete"
      style="background-color:#D33B2E ; width: 7rem; height: 100%; color: white; text-align: center;">删除
    </div>
    <div v-else @click="onSubmit"
      style="background-color:#D33B2E ; width: 7rem; height: 100%; color: white; text-align: center;">结算 </div>
  </div>


  <!-- <template #tip>
    你的收货地址不支持同城送, <span @click="onClickEditAddress">结算</span>
  </template> -->
  <!-- </van-submit-bar> -->
</template>

<script>
export default {
  name: "myCartFooter",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    carData: {
      type: Array,
      default: () => {
        return {};
      },
    },
    selectData: {
      type: Array,
    },
    type: {
      type: String,
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: false,
      submitLoading: false,
    };
  },
  methods: {
    handleCheckedChang(checked) {
      this.$emit("check", checked);
    },
    onClickEditAddress() { },
    async onSubmit() {
      this.submitLoading = true;
      let form = []
      this.carData.forEach(element => {
        if (this.selectData.includes(element.id)) {
          form.push(element)
        }
      });
      if (form.length == 0) {
        this.$Toast('请选择商品')
        return
      }
      // let rulesId
      // let returns = false
      // form.forEach((element, index) => {
      //   if (index == 0) {
      //     rulesId = element.ruleIds
      //   } else {
      //     if (rulesId != element.ruleIds) {
      //       returns = true
      //     }
      //   }
      // });
      // if (returns) {
      //   this.$Toast('配送方式不同的商品不可一起结算')
      //   return
      // }
      const res = await this.$api.checkIsDelivery(form);
      this.$router.push({
        path: "/confirmOrder",
        query: {
          type: 1,
          code: res.data
        },
      });
    },
    onDelete() {
      this.$emit("onDelete");
    },
    change(val) {
      this.checked = false
    }
  },
};
</script>

<style></style>
