<template>
  <div style="height: 100vh; overflow: hidden;display: flex;flex-direction: column;background-color: #f8f8f8;">
    <!-- <van-nav-bar
      title="购物车"
      left-text=""
      right-text="编辑"
      :left-arrow="false"
    /> -->
    <div style="display: flex;flex-direction: row;justify-content: space-between;">
      <span style="font-size: 1.2rem;font-weight: 600;margin: 1rem 0 0.5rem 1rem;padding-left: 0.5rem;color: #5b5454;">
        购物车</span>
      <span v-if="edit" style="font-size: 1rem;margin: 1.1rem 1rem 0 0;color: #D33B2E;" @click="onCheckEdit"> 完成</span>
      <span v-else style="font-size: 1rem;margin: 1.1rem 1rem 0 0;color: #D33B2E;" @click="onCheckEdit"> 编辑</span>
    </div>
    <div class="myCartContent" :style="{ paddingBottom: paddingbottom + 'rem' }">
      <ShopCards :list="carData" store-type="1" :edit="edit"></ShopCards>
    </div>
    <!-- <van-tabs @click="handleStoreTypeChange" v-model="storeType" style="flex: 1;">
      <van-tab name='2' title="三方" style="height: 100%;">
        <div class="myCartContent">
          <ShopCards :list="carData" store-type="2" :edit="edit"></ShopCards>
        </div>
      </van-tab>
      <van-tab name='1' title="自营">
        <div class="myCartContent">
          <ShopCards :list="carData" store-type="1" :edit="edit"></ShopCards>
        </div>
      </van-tab>
    </van-tabs> -->
    <!-- 底部 -->
    <AllNav v-if="ticketType == 'E4'"></AllNav>

  </div>
</template>

<script>
import { Checkbox, CheckboxGroup } from "vant";
import Footer from "./MyCartFooter.vue";
import ShopCards from './ShopCards.vue';
import AllNav from "../../components/footnav/AllNav.vue";
const typeMaps = [
  {
    typeCode: '1',
    typeValue: '自营',
  },
  {
    typeCode: '2',
    typeValue: '三方',
  }
]
export default {
  name: "myCart",
  props: {},
  data() {
    return {
      storeType: '1', // 商户类型
      userId: '', // 用户id
      freecaId: '', // 福利卡id
      pay: true,
      carData: [],
      result: [],
      value: 1,
      checked: false,
      username: "",
      deleteDate: [],
      edit: false,
      ticketType: '',
      paddingbottom: 0
    };
  },
  components: { Footer, Checkbox, CheckboxGroup, ShopCards, AllNav },
  methods: {
    onCheckEdit() {
      this.edit = !this.edit
    },
    handleStoreTypeChange() { // 商户类型切换change函数

    },
    // 请求购物车数据
    // async getCard() {
    //   const typeMap = typeMaps.find(item=> item.typeCode === this.storeType);
    //   const params = {
    //     userId: this.userId,
    //     sourceChannel: "购物车",
    //     type: typeMap ? typeMap.typeValue : typeMaps[0].typeValue,
    //     cardId: this.freecaId,

    //   }
    //   console.log(params);
    //   //const res = await this.$api.getCard(params)
    //   this.carData = res.data;
    // },

    // 全选
    checkAll() {

    },
    //删除单个
    delgoods(data, index) {
      console.log(this.carData);
      this.$Dialog
        .confirm({
          title: "删除商品",
          message: "是否将该商品删除",
        })
        .then(() => {
          this.deleteDate.length = 0;
          this.deleteDate.push(data);
          this.$api
            .deleteShop(this.deleteDate)
            .then((res) => {
              if (res.code == 1) {
                this.$Toast(res.msg);
                return
              }
              // console.log(res);
              // 页面显示的数据
              this.carData.splice(index, 1);
              console.log(this.carData);
              this.$store.commit("setCarNum", this.carData.length);
              localStorage.setItem(`${this.username}carNum`, this.carData.length);
            })
            .catch((err) => {
              // console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 总删除
    del() {
      let ids = [];
      // 获取删除的id
      this.carData.map((a) => {
        if (a.check) {
          ids.push(a);
        }
      });
      // 是否有选中商品
      if (ids.length > 0) {
        this.$Dialog
          .confirm({
            title: "删除商品",
            message: "是否将商品从购物车删除",
          })
          .then(() => {
            // on confirm
            this.$api
              .deleteShop(ids)
              .then((res) => {
                if (res.code == 1) {
                  this.$Toast(res.msg);
                  return
                }
                this.$Toast.success(res.msg);

                // 页面显示的数据
                this.carData = this.carData.filter((a) => {
                  return !a.check;
                });
                // 本地粗存
                this.$store.commit("setCarNum", this.carData.length);
                localStorage.setItem(`${this.username}carNum`, this.carData.length);
              })
              .catch((err) => {
                this.$Toast(err.msg);
              });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$Toast("未选中商品");
      }
    },
    // 结算
    goOrder() {
      // 先获取选中的商品
      let carDatas = this.carData.filter((a) => {
        return a.check;
      });
      // 有选中商品
      if (carDatas.length > 0) {
        // 本地储存
        localStorage.setItem("carDatas", JSON.stringify(carDatas));
        localStorage.setItem("idDirect", 0);
        this.$router.push("ToOrder");
      } else {
        this.$Toast("无选中商品");
      }
    },
  },

  mounted() {
    const queryData = this.$route.query; // 地址栏参数
    const userInfo = localStorage.getItem("userInfo"); // 用户信息

    this.storeType = queryData ? queryData.type : '';
    this.userId = userInfo ? JSON.parse(userInfo).id : '';
    this.freecaId = localStorage.getItem("cardId");
    this.ticketType = localStorage.getItem("entrCode");
    if (this.ticketType == 'E4') {
      this.paddingbottom = 4
    } else
      this.paddingbottom = 0
    // this.getCard(); // 查询购物车list
  },
  computed: {
    name() {
      return this.$store.state.nickname;
    },
    // 计算总价
    sum() {
      let sum = 0;
      this.carData.map((a) => {
        if (a.check) {
          return (sum += a.productNum * a.showPrice);
        }
      });
      if (sum === 0) {
        return sum;
      } else {
        return `${sum.toFixed(2)}点`;
      }
    },
  },
  watch: {},
};
</script>


<style lang="scss" scoped>
.van-nav-bar {
  background-color: #fff;
  color: #ffffff;
}

.van-nav-bar__title {
  color: #ffffff;
}

.myCartContent {
  overflow: auto;
  // height: 100%
  // height: calc(100vh - 180px);
  flex: 1;

}

.empt {
  transform: translateY(50%);
}

.empt1 {
  transform: translateY(30%);
}

.box {
  padding-bottom: 9rem;
  overflow: auto;

  .van-button--primary {
    margin-right: 15px;
  }

  .width-order {
    width: 50%;
    padding-right: 0.5rem;

    .width-sum {
      width: 45%;
      font-size: 0.8rem;
    }
  }
}

.title {
  background-color: rgb(236, 224, 216);
  height: 1.5rem;
}

.orderhead {
  background-color: white;
  width: 100%;
  position: absolute;
  bottom: 4rem;
  font-size: 0.8rem;
  height: 2.5rem;
}

.goods {
  background-color: #fff;
  margin-bottom: 5px;

  .img {
    width: 5rem;
    height: 4rem;
  }

  .name {
    height: 3rem;
  }

  .del-alsid {
    display: flex;
    align-items: center;
    width: 3rem;
    background-color: red;
    height: 100%;
    color: white;
    justify-content: center;
    font-size: 18px;
  }
}

.van-button {
  height: 1.5rem;
  width: 4rem;
  padding: 0;
}

body::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}
</style>
